import { ChangeDetectionStrategy, Component, model } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatError } from '@angular/material/form-field';
import { Router } from '@angular/router';
import { emailValidator, ErrorMessages } from '@examdojo/core/form';
import { FormConfirmFn, SubmitButtonDirective } from '@examdojo/core/form-submit-button';
import { IconComponent } from '@examdojo/core/icon';
import { ButtonComponent } from '@examdojo/ui/button';
import { TextInputComponent } from '@examdojo/ui/input';
import { TopHeaderComponent } from '@examdojo/ui/top-header/top-header.component';
import { IonButton } from '@ionic/angular/standalone';
import { ExamdojoAuthService } from '../abstract-auth.service';
import { OtpVerificationStepComponent } from '../otp-verification-step/otp-verification-step.component';

@Component({
  selector: 'dojo-sign-in-otp',
  templateUrl: './sign-in-otp.component.html',
  styleUrl: './sign-in-otp.component.scss',
  host: { class: 'flex flex-col h-full' },
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatButtonModule,
    MatError,
    ReactiveFormsModule,
    IconComponent,
    TextInputComponent,
    SubmitButtonDirective,
    ButtonComponent,
    TopHeaderComponent,
    IonButton,
    OtpVerificationStepComponent,
  ],
})
export class SignInOTPComponent {
  constructor(
    private readonly authService: ExamdojoAuthService,
    private readonly router: Router,
  ) {}

  readonly error = model('');

  readonly emailErrorMessages: ErrorMessages = {
    required: 'Email is required',
    email: 'Enter a valid email',
  };

  readonly emailForm = new FormGroup(
    {
      email: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required, emailValidator],
      }),
    },
    {
      updateOn: 'submit',
    },
  );

  readonly otpErrorMessages: ErrorMessages = {
    required: 'Code is required',
  };

  readonly otpCodeHasBeenSent = model(false);

  async sendOTPCode(emailForm: typeof this.emailForm = this.emailForm) {
    if (emailForm.invalid) {
      emailForm.markAllAsTouched();
      return;
    }

    const { email } = emailForm.getRawValue();

    const { error } = await this.authService.signInWithOTP(email);

    this.error.set(error?.message ?? '');

    return this.error();
  }

  goBack() {
    window.history.back();
  }

  readonly emailConfirmFn: FormConfirmFn<typeof this.emailForm> = async (form) => {
    const error = await this.sendOTPCode(form);

    if (!error) {
      this.otpCodeHasBeenSent.set(true);
    }
  };

  handleOtpVerificationSuccess() {
    this.router.navigate(['/'], { queryParamsHandling: 'preserve' });
  }
}
