import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatError } from '@angular/material/form-field';
import { Router } from '@angular/router';
import { ClassicButtonComponent } from '@examdojo/core/button';
import { ErrorHandlerService } from '@examdojo/core/error-handling';
import { ErrorMessages } from '@examdojo/core/form';
import { FormConfirmFn, SubmitButtonDirective } from '@examdojo/core/form-submit-button';
import { PasswordInputComponent } from '@examdojo/core/password-input';
import { ToastService } from '@examdojo/toast';
import { debounceTime, EMPTY, tap } from 'rxjs';
import { ExamdojoAuthService } from '../abstract-auth.service';

@Component({
  selector: 'dojo-reset-password',
  templateUrl: './reset-password.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatButtonModule,
    MatError,
    ReactiveFormsModule,
    ClassicButtonComponent,
    PasswordInputComponent,
    SubmitButtonDirective,
  ],
})
export class ResetPasswordComponent {
  constructor(
    private readonly router: Router,
    private readonly toastService: ToastService,
    private readonly authService: ExamdojoAuthService,
    private readonly errorHandlerService: ErrorHandlerService,
  ) {}

  error = '';

  readonly passwordErrorMessages: ErrorMessages = {
    required: 'Password is required',
    minlength: 'Password must be at least 6 characters long',
  };

  readonly confirmPasswordErrorMessages: ErrorMessages = {
    required: 'Confirm password is required',
    passwordMismatch: 'Passwords do not match',
  };

  static passwordMatchValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const password = control.get('password');
      const confirmPassword = control.get('confirmPassword');

      if (password && confirmPassword && password.value !== confirmPassword.value) {
        confirmPassword.setErrors({ passwordMismatch: true });
        return { passwordMismatch: true };
      }

      return null;
    };
  }

  readonly form = new FormGroup(
    {
      password: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required, Validators.minLength(6)],
      }),
      confirmPassword: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
    },
    {
      updateOn: 'submit',
      validators: ResetPasswordComponent.passwordMatchValidator(),
    },
  );

  readonly confirmFn: FormConfirmFn<typeof this.form> = async (form) => {
    if (form.invalid) {
      return;
    }

    const { password } = form.getRawValue();

    this.authService
      .updateUser({
        password: password,
      })
      .pipe(
        tap(() => {
          this.error = '';
          this.toastService.success('Password reset successfully');
        }),
        debounceTime(1500),
        tap(() => {
          this.router.navigate(['/']);
        }),
        this.errorHandlerService.catchError('', (error) => {
          if (error instanceof Error) {
            this.error = error.message;
          }
          return EMPTY;
        }),
      )
      .subscribe();
  };
}
