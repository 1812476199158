import { ActivatedRoute, Router } from '@angular/router';
import { ErrorHandlerService } from '@examdojo/core/error-handling';
import { SUPABASE_AUTH_STORAGE_KEY } from '@examdojo/core/supabase';
import { ToastService } from '@examdojo/core/toast';
import { mapToVoid } from '@examdojo/rxjs';
import { resetStores } from '@examdojo/state';
import { ExamdojoSupabaseService } from '@examdojo/supabase';
import {
  AuthChangeEvent,
  AuthError,
  AuthResponse,
  AuthTokenResponse,
  AuthTokenResponsePassword,
  AuthUser,
  OAuthResponse,
  Session,
  UserAttributes,
} from '@supabase/supabase-js';
import { BehaviorSubject, filter, from, map, Observable, startWith, tap, throwError } from 'rxjs';
import { isAuthApiError } from './auth-error.model';
import { AuthUrlParts, OidcProvider } from './auth.model';
import { REDIRECT_TO_PARAM } from './redirect-to.guard';

export const EMAIL_CHANGE_SUCCESS_QUERY_PARAM = 'emailChangeSuccess';
export const RESET_PASSWORD_PARAM = 'resetPassword';

export abstract class ExamdojoAuthService {
  protected constructor(
    protected readonly supabase: ExamdojoSupabaseService,
    protected readonly router: Router,
    protected readonly activatedRoute: ActivatedRoute,
    protected readonly errorHandlerService: ErrorHandlerService,
    protected readonly toastService: ToastService,
  ) {
    this.listenToSupabaseAuthStateChanges();
  }

  // TODO: Make it more explicit
  /**
   * * `null` - the authorization state is not yet known
   * * `false` - user is not authenticated
   * * `User` - user is authenticated
   */
  protected readonly currentUser$$ = new BehaviorSubject<AuthUser | null | false>(null);

  readonly currentUser$ = this.currentUser$$.asObservable();

  private readonly authStateChange$ = new Observable<AuthChangeEvent>((subscriber) => {
    const {
      data: { subscription },
    } = this.supabase.client.auth.onAuthStateChange((event) => {
      subscriber.next(event);
    });

    return () => {
      subscription.unsubscribe();
    };
  });

  readonly hasLoggedOut$ = this.authStateChange$.pipe(
    filter((ev) => ev === 'SIGNED_OUT'),
    mapToVoid(),
  );

  get currentUser() {
    return this.currentUser$$.value || null;
  }

  readonly signedIn$ = this.currentUser$.pipe(map((user) => !!user));

  readonly oAuthProfilePic$: Observable<string | null> = this.currentUser$.pipe(
    filter(Boolean),
    map((user) => user?.user_metadata['picture'] ?? null),
    startWith(null),
  );

  async getToken(): Promise<string | undefined> {
    const sessionResponse = await this.supabase.client.auth.getSession();

    if (sessionResponse.error) {
      throw sessionResponse.error;
    }

    return sessionResponse.data?.session?.access_token;
  }

  async verifyOTP(email: string, token: string): Promise<AuthResponse> {
    try {
      const response = await this.supabase.client.auth.verifyOtp({ email, token, type: 'email' });

      if (response.error) {
        throw response.error;
      }

      if (response.data?.session) {
        await this.setAuthSession(response.data.session);
      }

      return response;
    } catch (err: unknown) {
      if (isAuthApiError(err)) {
        return {
          data: {
            user: null,
            session: null,
          },
          error: new AuthError(err.message, err.status),
        };
      } else {
        throw err;
      }
    }
  }

  async signInWithOTP(email: string): Promise<AuthResponse> {
    try {
      const response = await this.supabase.client.auth.signInWithOtp({
        email,
      });

      if (response.error) {
        throw response.error;
      }

      return response;
    } catch (err) {
      this.errorHandlerService.error('[AuthService]: Error signing in with OTP', {
        toast: 'An error occurred while signing in. Please try again.',
        err,
      });

      throw err;
    }
  }

  async signInWithProvider(provider: OidcProvider): Promise<OAuthResponse> {
    const redirectToPath: string | undefined = this.activatedRoute.snapshot.queryParams[REDIRECT_TO_PARAM];
    const redirectTo = redirectToPath ? `${window.location.origin}${redirectToPath}` : window.location.origin;

    try {
      const response = await this.supabase.client.auth.signInWithOAuth({
        provider,
        options: {
          redirectTo,
        },
      });

      if (response.error) {
        throw response.error;
      }

      return response;
    } catch (err) {
      this.errorHandlerService.error(`[AuthService]: Error signing in with ${provider} provider`, {
        toast: `An error occurred while signing in with ${provider}. Please try again.`,
        err,
      });

      throw err;
    }
  }

  async signUp(email: string, password: string, firstName: string, lastName: string): Promise<AuthResponse> {
    try {
      const response = await this.supabase.client.auth.signUp({
        email,
        password,
        options: {
          data: {
            first_name: firstName,
            last_name: lastName,
          },
        },
      });

      if (response.error) {
        throw response.error;
      }

      if (response.data?.session) {
        await this.setAuthSession(response.data.session);
      }

      return response;
    } catch (err) {
      this.errorHandlerService.error('[AuthService]: Error signing up', {
        toast: 'An error occurred while signing up. Please try again.',
        err,
      });

      throw err;
    }
  }

  async signIn(email: string, password: string): Promise<AuthTokenResponsePassword['data']> {
    const response = await this.supabase.client.auth.signInWithPassword({
      email,
      password,
    });

    if (response.error) {
      throw response.error;
    }

    if (response.data?.session) {
      await this.setAuthSession(response.data.session);
    }

    return response.data;
  }

  async signOut() {
    const result = await this.supabase.client.auth.signOut({
      scope: 'local',
    });

    if (!result.error) {
      resetStores();
    }

    return result;
  }

  exchangeCodeForSession(code: string): Promise<AuthTokenResponse> {
    return this.supabase.client.auth.exchangeCodeForSession(code);
  }

  async setSessionFromMagicLinkIfExist(): Promise<void> {
    const fragment = document.location.hash.split('#')[1] ?? '';

    const paramMap = fragment.split('&').reduce(
      (acc, param) => {
        const [key, value] = param.split('=');
        acc[key] = value;
        return acc;
      },
      {} as Record<string, string>,
    );

    const accessTokenFromMagicLink = paramMap['access_token'];
    const refreshTokenFromMagicLink = paramMap['refresh_token'];

    if (!accessTokenFromMagicLink || !refreshTokenFromMagicLink) {
      return;
    }

    await this.setAuthSession({
      access_token: accessTokenFromMagicLink,
      refresh_token: refreshTokenFromMagicLink,
    });
  }

  async loadUser(): Promise<AuthUser | null> {
    if (this.currentUser) {
      console.debug('[AuthService]: ALREADY GOT USER');
      return this.currentUser;
    }

    try {
      await this.setSessionFromMagicLinkIfExist();

      const sessionResponse = await this.supabase.client.auth.getSession();

      if (sessionResponse.error) {
        this.currentUser$$.next(false);
        throw sessionResponse.error;
      }

      if (!sessionResponse.data.session) {
        this.currentUser$$.next(false);
        return this.currentUser;
      }

      const userResponse = await this.supabase.client.auth.getUser();

      if (userResponse.error) {
        this.currentUser$$.next(false);
        throw userResponse.error;
      }

      await this.supabase.client.realtime.setAuth();

      this.currentUser$$.next(userResponse.data.user);
      return this.currentUser;
    } catch (err: unknown) {
      if (isAuthApiError(err)) {
        this.errorHandlerService.error('[AuthService]: Loading user errored', {
          toast:
            err.status === 403
              ? 'Your session has expired. Please log in again to continue.'
              : 'An error occurred while loading the user.',
          err,
        });

        this.handleLogout();
        return null;
      } else {
        throw err;
      }
    }
  }

  updateUser(userData: UserAttributes) {
    const userAuth = this.currentUser$$.getValue();

    if (!userAuth) {
      return throwError(() => Error('User is not authenticated'));
    }

    return from(
      this.supabase.client.auth
        .updateUser(userData, { emailRedirectTo: `${window.location.origin}?${EMAIL_CHANGE_SUCCESS_QUERY_PARAM}=1` })
        .then(({ error, data }) => {
          if (error) {
            throw error;
          }

          return data;
        }),
    ).pipe(
      this.errorHandlerService.setHttpErrorMetadata({ entity: 'user', action: 'update' }),
      map(({ user }) => user),
      tap((user) => this.currentUser$$.next(user)),
    );
  }

  selectAccessToken(): Observable<string | undefined> {
    return from(this.supabase.client.auth.getSession()).pipe(
      map((sessionResponse) => {
        if (sessionResponse.error) {
          throw sessionResponse.error;
        }

        return sessionResponse.data?.session?.access_token;
      }),
    );
  }

  selectAuthProvider(): Observable<string | null> {
    return this.currentUser$.pipe(map((user) => (user ? user?.app_metadata?.provider ?? null : null)));
  }

  handleLogout() {
    localStorage.removeItem(SUPABASE_AUTH_STORAGE_KEY);
    this.currentUser$$.next(false);
    this.router.navigate(['/', AuthUrlParts.Login]);
  }

  async sendPasswordResetEmail(email: string) {
    try {
      const { error } = await this.supabase.client.auth.resetPasswordForEmail(email, {
        redirectTo: `${window.location.origin}/${AuthUrlParts.ResetPassword}`,
      });

      if (error) {
        throw error;
      }

      this.toastService.success('Password reset email sent successfully');
    } catch (error) {
      this.errorHandlerService.error('[AuthService]: Error sending password reset email', {
        toast: 'An error occurred while sending the password reset email. Please try again.',
        err: error,
      });
    }
  }

  async updatePassword(newPassword: string) {
    try {
      const { error } = await this.supabase.client.auth.updateUser({
        password: newPassword,
      });

      if (error) {
        throw error;
      }

      this.router.navigate(['/']);
    } catch (error) {
      this.errorHandlerService.error('[AuthService]: Error updating password', {
        toast: 'An error occurred while updating the password. Please try again.',
        err: error,
      });
    }
  }

  private setAuthSession({ access_token, refresh_token }: { access_token: string; refresh_token: string }) {
    return this.supabase.client.auth.setSession({
      access_token,
      refresh_token,
    });
  }

  private listenToSupabaseAuthStateChanges() {
    let currentSession: Session | null;
    this.supabase.client.auth.onAuthStateChange((event, session) => {
      if (session?.user?.id === currentSession?.user?.id) {
        return;
      }

      currentSession = session;
      console.debug('[AuthService]: Supabase auth changed session: ', session);

      if (event === 'SIGNED_OUT') {
        this.handleLogout();
        return;
      } else if (session && (event === 'SIGNED_IN' || event === 'TOKEN_REFRESHED')) {
        this.currentUser$$.next(session.user);
      }
    });
  }
}
