import { Component } from '@angular/core';
import { connectState } from '@examdojo/angular/util';
import { DurationRange } from '@examdojo/core/date-time';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BaseCellRendererComponent } from '../base-cell-renderer.component';

@UntilDestroy()
@Component({
  selector: 'y42-duration-cell-renderer',
  template: `<y42-duration [durationRange]="state.durationRange"></y42-duration>`,
  standalone: false,
})
export class DurationCellRendererComponent extends BaseCellRendererComponent<DurationRange> {
  public readonly state = connectState({
    durationRange: this.valueAsSingle$,
  });
}
