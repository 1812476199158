import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButtonToggleChange, MatButtonToggleModule } from '@angular/material/button-toggle';
import { connectState } from '@examdojo/angular/util';
import { StopPropagationModule } from '@examdojo/core/stop-propagation';
import { UntilDestroy } from '@ngneat/until-destroy';
import { IRowNode } from 'ag-grid-community';
import { map } from 'rxjs';
import { BaseCellRendererComponent, Params } from '../base-cell-renderer.component';

export type ToggleCellRendererParamsValue = boolean;

export interface ToggleCellRendererParams<T = unknown> {
  disabled?: boolean;
  onStateChange: (node: IRowNode<T>, newState: ToggleCellRendererParamsValue) => void;
}

@UntilDestroy()
@Component({
  selector: 'y42-toggle-cell-renderer',
  imports: [MatButtonToggleModule, StopPropagationModule],
  templateUrl: './toggle-cell-renderer.component.html',
  styleUrls: ['./toggle-cell-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleCellRendererComponent extends BaseCellRendererComponent<
  ToggleCellRendererParamsValue,
  unknown,
  Params<ToggleCellRendererParamsValue, unknown, ToggleCellRendererParams>
> {
  readonly state = connectState({
    isOn: this.value$,
    isDisabled: this.params$.pipe(map((params) => params.disabled)),
  });

  setState({ value }: MatButtonToggleChange) {
    this.params?.onStateChange(this.params.node, value);
  }
}
