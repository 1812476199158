/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ColDef,
  IAggFuncParams,
  ProcessCellForExportParams,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community';
import groupBy from 'lodash/groupBy';
import sumBy from 'lodash/sumBy';

// only works with GroupDistributionAgg
export const GroupComparator = function (valueA: any, valueB: any, nodeA: any, nodeB: any) {
  if (nodeA && nodeA.group && nodeB && nodeB.group && valueA && valueB) {
    const valA = Object.entries(groupBy(valueA, 'id')).map(([_, state]) => ({
      total_count: sumBy(state, 'count'),
    }));
    const valB = Object.entries(groupBy(valueB, 'id')).map(([_, state]) => ({
      total_count: sumBy(state, 'count'),
    }));

    const aTotalCount = valA[0]?.total_count || 0;
    const bTotalCount = valB[0]?.total_count || 0;

    if (aTotalCount < bTotalCount) {
      return -1;
    }
    if (aTotalCount > bTotalCount) {
      return 1;
    }
    if (aTotalCount === bTotalCount) {
      if (valueA[0]?.type === 'null' && valueB[0]?.type !== 'null') {
        return -1;
      } else {
        if (valueA[0]?.type === valueB[0]?.type) {
          return -1;
        } else {
          return 1;
        }
      }
    }
    return 0;
  } else {
    if (valueA < valueB) {
      return -1;
    }
    if (valueA > valueB) {
      return 1;
    }
    return 0;
  }
};

export const GroupDistributionAgg = (params: any) => {
  if (params.rowNode.group && params.rowNode.level !== -1) {
    return Object.entries(groupBy(params.values)).map(([type, items]) => ({ type, count: items.length }));
  }

  return undefined;
};

export function descriptionAggregationFunction(params: IAggFuncParams) {
  return params.values.length ? params.values[0]?.description : '';
}

export function assetStatusAggregationFunction(params: IAggFuncParams) {
  return params.values[0] ?? '';
}

export function pathAggregationFunction(params: IAggFuncParams) {
  return params.rowNode?.allLeafChildren?.[0]?.data?.$path ?? '';
}

export const RunStatusValueGetter = (params: ValueGetterParams) => {
  if (params.data.last_runs?.length > 0) {
    return params.data.last_runs.map((_val: any) => _val.status);
  } else {
    return [];
  }
};

export const HeaderWithIconTemplate = function (link: string) {
  return (
    `<div class="ag-cell-label-container" role="presentation">` +
    `  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>` +
    `  <div ref="eLabel" class="ag-header-cell-label" role="presentation">` +
    `    <img width="12" class="mr-1 ag-header-cell-icon" src="${link}" alt=""> <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>` +
    `    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>` +
    `    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>` +
    `    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon pl-2"></span>` +
    `    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon pl-2"></span>` +
    `    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>` +
    `  </div>` +
    `</div>`
  );
};

export function processCellForClipboard(params: ProcessCellForExportParams) {
  const colDef: ColDef = params.column.getColDef();
  if (colDef.valueFormatter && typeof colDef.valueFormatter === 'function') {
    return colDef.valueFormatter({
      ...params,
      data: params.node?.data,
      colDef: colDef,
    } as ValueFormatterParams);
  } else if (colDef.valueFormatter && typeof colDef.valueFormatter === 'string') {
    return colDef.valueFormatter;
  }
  if (typeof params.value === 'object') {
    return JSON.stringify(params.value);
  }

  return params.value;
}
