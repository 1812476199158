<div class="mx-auto flex h-full w-full max-w-[380px] flex-col justify-center px-4 py-4">
  <div class="text-typography-primary dark:font-medium-dark pb-6 text-center text-xl font-medium">
    {{ 'examdojo.registration.create_account' | transloco }}
  </div>

  <div class="flex flex-col gap-3">
    <dojo-classic-button (click)="loginWithGoogleProvider()" [fullWidth]="true" [isBorderShow]="true">
      <div class="flex items-center">
        <ion-img class="h-4 w-4" src="./assets/images/google-logo.png" />
        <span class="ml-3">{{ 'examdojo.registration.sign_in_with_google' | transloco }}</span>
      </div>
    </dojo-classic-button>
  </div>

  <span
    class="small:my-6 font-medium-exam relative my-3 text-center text-sm text-neutral-400 after:absolute after:left-0 after:top-[50%] after:h-px after:w-full after:bg-neutral-200 after:content-['']"
  >
    <span class="z-1 relative bg-white px-2">or sign up with email</span>
  </span>

  <form [formGroup]="form" class="flex w-full flex-col" autocomplete="off">
    <div class="mb-4 grid grid-cols-2 gap-4">
      <y42-text-input
        label="First name"
        [formCtrl]="form.controls.firstName"
        [errorMessages]="{
          required: 'First name is required'
        }"
      />

      <y42-text-input
        label="Last name"
        [formCtrl]="form.controls.lastName"
        [errorMessages]="{
          required: 'Last name is required'
        }"
      />
    </div>

    <y42-text-input
      class="mb-4"
      [label]="'Email'"
      [type]="'email'"
      [formCtrl]="form.controls.email"
      [errorMessages]="{
        required: 'Email is required',
        exists: 'Email already exists',
        api: 'Could not create account',
        email: 'Enter a valid email'
      }"
    />
    <y42-user-password-input [formCtrl]="form.controls.password" class="mb-6" [label]="'Password'" />

    @if (error) {
      <mat-error class="mb-4">
        {{ error }}
      </mat-error>
    }

    <dojo-classic-button
      color="primary"
      class="w-full"
      type="submit"
      submitButton
      [confirmFn]="confirmFn"
      [attr.data-test]="'register-button-create-account'"
    >
      <span class="text-white">Create account</span>
    </dojo-classic-button>
  </form>

  <p class="action-text mt-3 text-center">
    {{ 'examdojo.registration.already_have_an_account' | transloco }}
    <a class="dashed-link" [routerLink]="loginUrl">{{ 'examdojo.registration.sign_in' | transloco }}</a>
  </p>
</div>
