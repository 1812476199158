import { Directive, HostBinding, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AgGridAngular } from 'ag-grid-angular';
import { GridApi } from 'ag-grid-community';
import { debounceTime, fromEvent, map, merge, startWith, switchMap, take, tap } from 'rxjs';
import { DataNoRowOverlayComponent } from '../overlays/data-no-row-overlay/data-no-row-overlay.component';

/**
 * Applies basic y42-wide defaults to `ag-grid`.
 */
@UntilDestroy()
// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({
  selector: 'ag-grid-angular',
  standalone: false,
})
export class AgGridDirective implements OnInit {
  constructor(private readonly agGrid: AgGridAngular) {}

  @HostBinding('class') defaultClasses = 'w-full h-full';
  @HostBinding('class.hide-filter-menu') get hideFilterMenuClass() {
    const data = this.agGrid.rowData;
    return data && data.length === 1;
  }

  ngOnInit(): void {
    this.agGrid.gridReady
      .pipe(
        take(1),
        map((params) => params.api),
        tap((api) => this.initializeGrid(api)),
        switchMap((api) => merge(this.resizeGridOnWindowResize(api), this.toggleNoRowOverlayVisibility(api))),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private initializeGrid(api: GridApi) {
    if (this.agGrid.gridOptions) {
      this.agGrid.gridOptions.noRowsOverlayComponent = DataNoRowOverlayComponent;
    }
    api.closeToolPanel();
    api.sizeColumnsToFit();
  }

  private resizeGridOnWindowResize(api: GridApi) {
    return fromEvent(window, 'resize').pipe(
      debounceTime(200),
      tap(() => () => api.sizeColumnsToFit()),
    );
  }

  private toggleNoRowOverlayVisibility(api: GridApi) {
    return this.agGrid.modelUpdated.pipe(
      startWith(false),
      tap(() => {
        const hasRows = !!api.getDisplayedRowCount();
        if (hasRows) {
          api.hideOverlay();
        } else {
          api.showNoRowsOverlay();
        }
      }),
    );
  }
}
