import { FormControl } from '@angular/forms';
import { connectState } from '@examdojo/angular/util';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, Observable, of, startWith, switchMap, tap } from 'rxjs';
import { BaseCellRendererComponent } from './base-cell-renderer.component';

export interface BaseDropdownCellRendererConfig<T> {
  idProperty?: keyof T;
  displayNameProperty?: keyof T;
  items: T[];
  selectedItem$: Observable<T | null>;
  itemSelected?: (item: T[keyof T] | T | null) => void;
  toString: () => string;
}

@UntilDestroy()
export class BaseDropdownCellRendererComponent<T> extends BaseCellRendererComponent<BaseDropdownCellRendererConfig<T>> {
  constructor() {
    super();
    this.control.valueChanges
      .pipe(
        switchMap((newValue) => {
          return this.valueAsSingle$.pipe(
            tap((config) => {
              if (!config) {
                return;
              }
              config.itemSelected?.(newValue && config.idProperty ? newValue[config.idProperty] : newValue);
            }),
          );
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  readonly state = connectState({
    items: this.valueAsSingle$.pipe(map((value) => value?.items ?? [])),
    selectedItem: this.valueAsSingle$.pipe(
      switchMap((value) => {
        return value?.selectedItem$?.pipe(startWith(null)) ?? of(null);
      }),
    ),
    idProperty: this.valueAsSingle$.pipe(map((value) => value?.idProperty)),
    displayNameProperty: this.valueAsSingle$.pipe(map((value) => value?.displayNameProperty)),
  });

  readonly control = new FormControl<T | null>(null, { nonNullable: false });
}
