import { NgClass } from '@angular/common';
import { Component, Input, QueryList, ViewChildren } from '@angular/core';
import { FormArray, FormControl, ReactiveFormsModule } from '@angular/forms';
import { connectState, RxAfterViewInit } from '@examdojo/angular/util';
import { TextInputComponent } from '@examdojo/ui/input';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, Observable, tap } from 'rxjs';

@RxAfterViewInit()
@UntilDestroy()
@Component({
  selector: 'dojo-ote-input',
  templateUrl: './ote-input.component.html',
  styleUrls: ['./ote-input.component.scss'],
  host: { class: 'flex items-center gap-2' },
  imports: [TextInputComponent, ReactiveFormsModule, TextInputComponent, NgClass],
})
export class OteInputComponent {
  constructor() {
    this.allowOnlyDigits().pipe(untilDestroyed(this)).subscribe();
  }

  readonly ngAfterViewInit$!: Observable<void>;

  private readonly digitsLength = 6;
  private readonly codeRegExp = /^\d{6}$/;

  @Input({ required: true })
  formCtrl!: FormControl<string>;

  @ViewChildren(TextInputComponent) inputs?: QueryList<TextInputComponent>;

  readonly formArray = new FormArray(
    Array.from({ length: this.digitsLength }).map(() => new FormControl('', { nonNullable: true })),
  );

  readonly state = connectState({});

  onKeyup(index: number, event: KeyboardEvent) {
    event.stopPropagation();
    if (event.key === 'Backspace') {
      this.focusInput(index - 1);
    }
  }

  onPaste(event: ClipboardEvent) {
    event.preventDefault();

    const clipboardData = event.clipboardData;
    if (!clipboardData) {
      return;
    }

    const pastedValue = clipboardData.getData('text').trim();
    if (!pastedValue.match(this.codeRegExp)) {
      return;
    }

    const digits = pastedValue.split('').slice(0, this.digitsLength);

    this.formArray.patchValue(digits, { emitEvent: false });
    const code = digits.join('');
    this.formCtrl.patchValue(code, { emitEvent: true });

    this.focusInput(digits.length - 1);
  }

  focusNextInput(index: number) {
    const digit = this.formArray.at(index).value;

    if (digit === '') {
      return;
    }

    let nextIndex = index;

    if (digit.match(this.codeRegExp)) {
      nextIndex = this.digitsLength - 1;
    } else if (this.isValidDigit(digit)) {
      nextIndex = index + 1;
    }

    this.focusInput(nextIndex);
  }

  private focusInput(index: number) {
    if (index < 0) {
      index = 0;
    } else if (index > this.digitsLength - 1) {
      index = this.digitsLength - 1;
    }
    this.inputs?.get(index)?.focus();
  }

  private allowOnlyDigits() {
    return this.formArray.valueChanges.pipe(
      map((digits) => {
        const codeCandidate = digits.find((digit) => digit.match(this.codeRegExp));
        return codeCandidate ? codeCandidate.split('') : digits;
      }),
      map((digits) => digits.map((digit) => (this.isValidDigit(digit) ? digit.at(0) || '' : ''))),
      tap((normalizedDigits) => {
        this.formArray.patchValue(normalizedDigits, {
          emitEvent: false,
        });

        const code = normalizedDigits.join('');
        if (code.match(this.codeRegExp)) {
          this.formCtrl.patchValue(code, { emitEvent: true });
        }
      }),
    );
  }

  private isValidDigit(digit: string) {
    return !isNaN(Number.parseInt(digit, 10));
  }
}
