<dojo-top-header />
<div class="space-for-scroll safe-area-bottom flex h-full flex-col bg-white">
  <div class="flex flex-1 flex-col justify-center py-4">
    <div class="small:w-[360px] small:mx-auto flex h-full min-h-0 flex-col justify-center">
      @if (!otpCodeHasBeenSent()) {
        <form [formGroup]="emailForm" class="small:pb-0 relative flex h-full w-full flex-col justify-center pb-[50px]">
          <div class="mb-8">
            <ion-button class="go-back-button inline-flex items-center" (click)="goBack()">
              <dojo-icon [icon]="'arrow-left'" />
              <span class="ml-1">Go back</span>
            </ion-button>
          </div>
          <h2 class="text-display-md font-medium-exam mb-6 text-neutral-950">What is your email?</h2>
          <div class="mb-[50px] sm:mb-2">
            <dojo-text-input
              [formCtrl]="emailForm.controls.email"
              data-test="sign-in-input-email"
              autocomplete="email"
              [errorMessages]="emailErrorMessages"
              [hasInitialFocus]="true"
            ></dojo-text-input>

            @if (error()) {
              <mat-error>
                {{ error() }}
              </mat-error>
            }
          </div>
          <div class="small:static small:inline-flex absolute bottom-0 left-0 right-0 mt-8 gap-2">
            <dojo-button submitButton [confirmFn]="emailConfirmFn" data-test="login-button"> Send code </dojo-button>
          </div>
        </form>
      } @else {
        <dojo-otp-verification-step
          (canceled)="otpCodeHasBeenSent.set(false)"
          [email]="emailForm.getRawValue().email"
          (completed)="handleOtpVerificationSuccess()"
        />
      }
    </div>
  </div>
</div>
