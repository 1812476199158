import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { Icon } from '@examdojo/core/icon';
import { INoRowsOverlayAngularComp } from 'ag-grid-angular';
import { INoRowsOverlayParams } from 'ag-grid-community';

export interface DataNoRowOverlayParams {
  text: string;
  body: string;
  icon?: Icon;
  iconColor?: string;
  overlayClasses?: string;
}

type IDataNoRowOverlayParams = INoRowsOverlayParams & DataNoRowOverlayParams;

@Component({
  selector: 'y42-data-no-row-overlay',
  templateUrl: './data-no-row-overlay.component.html',
  styleUrls: ['./data-no-row-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class DataNoRowOverlayComponent implements INoRowsOverlayAngularComp {
  public params!: IDataNoRowOverlayParams;

  @HostBinding('class') get overlayClasses() {
    return this.params.overlayClasses || '';
  }

  agInit(params: IDataNoRowOverlayParams): void {
    this.params = params;
  }
}
