import {
  MatPasswordStrengthComponent,
  MatPasswordStrengthModule,
} from '@angular-material-extensions/password-strength';
import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { FormControl, ValidationErrors } from '@angular/forms';
import { connectState, RxAfterViewInit, RxInput, RxOnInit, RxViewChild } from '@examdojo/angular/util';
import { AnimationDuration, getAnimationAppearDisappearWithHeight } from '@examdojo/animation';
import { ErrorMessages } from '@examdojo/core/form';
import { PasswordInputComponent } from '@examdojo/core/password-input';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import omit from 'lodash/omit';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  map,
  merge,
  Observable,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';

@RxOnInit()
@RxAfterViewInit()
@UntilDestroy()
@Component({
  selector: 'y42-user-password-input',
  templateUrl: './user-password-input.component.html',
  styleUrls: ['./user-password-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatPasswordStrengthModule, PasswordInputComponent],
  animations: [
    getAnimationAppearDisappearWithHeight({ enter: AnimationDuration.Medium, leave: AnimationDuration.Medium }),
  ],
})
export class UserPasswordInputComponent {
  constructor() {
    merge(this.updatePasswordStrengthErrorsOnFormControlStatusChange(), this.updateFormControlOnPasswordChange())
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  @Input({ required: true })
  @RxInput()
  formCtrl!: FormControl<string>;
  private readonly formCtrl$!: Observable<FormControl<string>>;

  @Input() label = '';

  @Input()
  @RxInput()
  errorMessages: ErrorMessages = {};
  private readonly errorMessages$!: Observable<ErrorMessages>;

  @ViewChild(MatPasswordStrengthComponent)
  @RxViewChild()
  passwordComponent!: MatPasswordStrengthComponent;
  private readonly passwordComponent$!: Observable<MatPasswordStrengthComponent>;

  private readonly TOO_WEAK_MESSAGE = 'Too weak';
  private readonly orderedThresholds: Array<{ threshold: number; message: string }> = [
    { threshold: 40, message: this.TOO_WEAK_MESSAGE },
    { threshold: 60, message: 'Weak' },
    { threshold: 80, message: 'Good' },
    { threshold: 100, message: 'Strong' },
  ];

  private readonly passwordStrength$$ = new BehaviorSubject(0);
  private readonly passwordStrength$ = this.passwordStrength$$.asObservable();

  private readonly passwordStrengthFormControl$: Observable<FormControl<string>> = this.passwordComponent$.pipe(
    map((passwordComponent) => passwordComponent.passwordFormControl),
  );

  private readonly inputErrorMessages$ = this.errorMessages$.pipe(
    map((errorMessages) => ({
      ...errorMessages,
      required: 'Password is required',
    })),
  );
  private readonly errorMessagesKeys$ = this.errorMessages$.pipe(map((errorMessages) => Object.keys(errorMessages)));

  private readonly passwordStrengthFormControlStatusChange$ = this.passwordStrengthFormControl$.pipe(
    switchMap((passwordStrengthFormControl) =>
      passwordStrengthFormControl.statusChanges.pipe(map(() => passwordStrengthFormControl)),
    ),
  );

  private readonly hasPasswordStrengthErrors$ = combineLatest([
    this.passwordStrengthFormControlStatusChange$,
    this.errorMessagesKeys$,
  ]).pipe(
    map(([passwordStrengthFormControl, errorMessagesKeys]) => {
      const errors = passwordStrengthFormControl.errors;
      if (!errors || errors['required']) {
        return false;
      }
      return Object.keys(omit(errors, errorMessagesKeys)).length > 0;
    }),
    distinctUntilChanged(),
  );

  private readonly hasRequiredError$ = this.passwordStrengthFormControlStatusChange$.pipe(
    map((passwordStrengthFormControl) => !!passwordStrengthFormControl.errors?.['required']),
  );

  private readonly passwordStrengthScoreLabel$ = this.passwordStrength$.pipe(
    map(
      (score) => this.orderedThresholds.find(({ threshold }) => score <= threshold)?.message ?? this.orderedThresholds,
    ),
  );

  readonly state = connectState({
    hasPasswordStrengthErrors: this.hasPasswordStrengthErrors$,
    hasRequiredError: this.hasRequiredError$,
    errorMessages: this.inputErrorMessages$,
    passwordStrengthScore: this.passwordStrength$,
    passwordStrengthScoreLabel: this.passwordStrengthScoreLabel$,
  });

  setPasswordStrength(value: number) {
    this.passwordStrength$$.next(value);
  }

  private updateFormControlOnPasswordChange() {
    return this.passwordStrengthFormControl$.pipe(
      switchMap((passwordStrengthFormControl) => passwordStrengthFormControl.valueChanges),
      withLatestFrom(this.formCtrl$),
      tap(([value, formControl]) => {
        formControl.patchValue(value);
      }),
    );
  }

  private updatePasswordStrengthErrorsOnFormControlStatusChange() {
    const passwordStrengthErrorKey = 'passwordStrength';

    return combineLatest([
      this.passwordStrengthFormControl$,
      this.formCtrl$.pipe(switchMap((formControl) => formControl.statusChanges.pipe(map(() => formControl)))),
      this.errorMessagesKeys$,
    ]).pipe(
      tap(([passwordStrengthFormControl, formControl, errorMessagesKeys]) => {
        const passwordStrengthOnlyErrors = omit(passwordStrengthFormControl.errors || {}, errorMessagesKeys);
        const formControlErrors = omit(formControl.errors || {}, passwordStrengthErrorKey);

        const newErrors: ValidationErrors = {
          ...passwordStrengthOnlyErrors,
          ...formControlErrors,
        };

        passwordStrengthFormControl.setErrors(Object.keys(newErrors).length === 0 ? null : newErrors);
        passwordStrengthFormControl.markAsDirty();
        passwordStrengthFormControl.markAsTouched();

        if (Object.keys(passwordStrengthOnlyErrors).length > 0) {
          formControl.setErrors(
            {
              [passwordStrengthErrorKey]: true,
            },
            { emitEvent: false },
          );
        }
      }),
    );
  }
}
