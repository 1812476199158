import { DistributionTypes, SeparatorType } from '@examdojo/core/model';
import { IRowNode } from 'ag-grid-community';

export interface BarRendererParams {
  maxValueColumns?: Record<string, number>;
  max: number;
  format: string;
  separator: SeparatorType;
  displayPercent: boolean;
  textColor: string;
  diffColumn: string[];
}

export interface ImageCellRendererParams {
  width: number;
  height: number;
  getTooltip?: (node: IRowNode) => string;
  getImageSrc?: (node: IRowNode) => string;
}

export interface SparklineOptions {
  sparklineOptions: {
    type: DistributionTypes;
    highlightStyle: {
      fill: string;
    };
    axis: { type: string };
    valueAxisDomain: [0, 1];
  };
}
export type ComponentsName =
  | 'linkRendererComponent'
  | 'imageRendererComponent'
  | 'cellBarRendererComponent'
  | 'agSparklineCellRenderer';

export type CellRendererComponents = Record<
  ComponentsName,
  { component: ComponentsName; params?: BarRendererParams | ImageCellRendererParams | SparklineOptions }
>;

export const cellRendererComponents: CellRendererComponents = {
  linkRendererComponent: { component: 'linkRendererComponent' },
  imageRendererComponent: {
    component: 'imageRendererComponent',
    params: {
      width: 50,
      height: 50,
    },
  },
  cellBarRendererComponent: {
    component: 'cellBarRendererComponent',
    params: {
      max: 0,
      format: '0',
      separator: SeparatorType.NONE,
      displayPercent: false,
      textColor: '#ffffff',
      diffColumn: ['none'],
    },
  },
  agSparklineCellRenderer: {
    component: 'agSparklineCellRenderer',
    params: {
      sparklineOptions: {
        type: DistributionTypes.Column,
        highlightStyle: {
          fill: 'orange',
        },
        axis: { type: 'category' },
        valueAxisDomain: [0, 1],
      },
    },
  },
};
