import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { connectState, InputObservable } from '@examdojo/angular/util';
import { Icon, IconComponent } from '@examdojo/core/icon';
import { UntilDestroy } from '@ngneat/until-destroy';
import { isObservable, Observable, of, switchMap } from 'rxjs';

export interface NormalizedMenuOption {
  label: string | Observable<string>;
  icon?: Icon;
  action: () => void;
  classes: Observable<string>;
}

@UntilDestroy()
@Component({
  selector: 'y42-menu-option',
  templateUrl: './menu-option.component.html',
  styleUrls: ['./menu-option.component.scss'],
  imports: [NgIf, MatMenuModule, MatTooltipModule, IconComponent],
})
export class MenuOptionComponent {
  @Input({ required: true })
  @InputObservable()
  option!: NormalizedMenuOption;
  option$!: Observable<NormalizedMenuOption | undefined>;

  readonly state = connectState({
    classes: this.option$.pipe(switchMap((option) => option?.classes || of(''))),
    label: this.option$.pipe(switchMap((option) => this.normalizeStringField(option?.label))),
  });

  private normalizeStringField(field: string | Observable<string> | undefined) {
    if (!field) {
      return of('');
    }
    return isObservable(field) ? field : of(field);
  }
}
