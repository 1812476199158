import { Component } from '@angular/core';
import { connectState } from '@examdojo/angular/util';
import { UserEnvironmentService } from '@examdojo/core/environment';
import {
  FormatBy,
  formatValueConversion,
  MAX_FRACTION_DEFAULT,
  MIN_FRACTION_DEFAULT,
} from '@examdojo/util/format-value-conversion';
import { isNullish } from '@examdojo/util/nullish';
import { UntilDestroy } from '@ngneat/until-destroy';
import { map } from 'rxjs';
import { BaseCellRendererComponent } from '../base-cell-renderer.component';

@UntilDestroy()
@Component({
  selector: 'y42-format-value-conversion-cell-renderer',
  template: `<span>{{ state.numberFormatted }}</span>`,
  standalone: false,
})
export class FormatValueConversionCellRendererComponent extends BaseCellRendererComponent<{
  number: number;
  prefix?: string;
  suffix?: string;
  formatBy: FormatBy;
  minFraction?: number;
  maxFraction?: number;
}> {
  constructor(private readonly userEnvironmentService: UserEnvironmentService) {
    super();
  }

  public readonly state = connectState({
    numberFormatted: this.valueAsSingle$.pipe(
      map((val) => {
        if (isNullish(val)) {
          return '-';
        }

        const formatBy = val.formatBy;
        const minFraction = isNullish(val.minFraction) ? MIN_FRACTION_DEFAULT : val.minFraction;
        const maxFraction = isNullish(val.maxFraction) ? MAX_FRACTION_DEFAULT : val.maxFraction;

        const formatted = formatValueConversion(
          val.number,
          this.userEnvironmentService.locale || 'en',
          formatBy,
          minFraction,
          maxFraction,
        );
        return `${val.prefix || ''}${formatted}${val.suffix || ''}`;
      }),
    ),
  });
}
