import index from '../cjs/index.js';
const {
  PostgrestClient,
  PostgrestQueryBuilder,
  PostgrestFilterBuilder,
  PostgrestTransformBuilder,
  PostgrestBuilder,
  PostgrestError
} = index;
export { PostgrestBuilder, PostgrestClient, PostgrestFilterBuilder, PostgrestQueryBuilder, PostgrestTransformBuilder, PostgrestError };

// compatibility with CJS output
export default {
  PostgrestClient,
  PostgrestQueryBuilder,
  PostgrestFilterBuilder,
  PostgrestTransformBuilder,
  PostgrestBuilder,
  PostgrestError
};