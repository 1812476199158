<div class="mx-auto flex h-full w-full max-w-[380px] flex-col justify-center px-4 py-4">
  <div class="text-typography-primary dark:font-medium-dark pb-6 text-center text-xl font-medium">
    Sign in to your account
  </div>

  <div class="flex flex-col gap-3">
    <dojo-classic-button (click)="loginWithGoogleProvider()" [fullWidth]="true" [isBorderShow]="true">
      <div class="flex items-center">
        <ion-img class="h-4 w-4" src="./assets/images/google-logo.png" />
        <span class="ml-3">{{ 'examdojo.registration.sign_in_with_google' | transloco }}</span>
      </div>
    </dojo-classic-button>
  </div>

  <span
    class="small:my-6 font-medium-exam relative my-3 text-center text-sm text-neutral-400 after:absolute after:left-0 after:top-[50%] after:h-px after:w-full after:bg-neutral-200 after:content-['']"
  >
    <span class="z-1 relative bg-white px-2">or sign in with email</span>
  </span>

  <form [formGroup]="form" class="flex w-full flex-col">
    <y42-text-input
      label="Email"
      [formCtrl]="form.controls.email"
      data-test="sign-in-input-email"
      autocomplete="email"
      [errorMessages]="emailErrorMessages"
      [hasInitialFocus]="isDesktop"
    />

    <y42-password-input
      class="mb-6 mt-4"
      label="Password"
      [formCtrl]="form.controls.password"
      data-test="sign-in-input-password"
      autocomplete="current-password"
      [errorMessages]="{
        required: 'Password is required'
      }"
    />

    <dojo-classic-button color="primary" class="w-full" submitButton [confirmFn]="confirmFn" data-test="login-button">
      <span class="text-white">Sign in</span>
    </dojo-classic-button>

    @if (error) {
      <mat-error class="mt-2 text-center">
        {{ error }}
      </mat-error>

      <div class="mt-3 text-center">
        <a class="dashed-link" (click)="sendPasswordResetLink()" data-test="forgot-password-button">Forgot password?</a>
      </div>
    }
  </form>

  <p
    class="text-typography-secondary py-4 text-center"
    [innerHTML]="
      'examdojo.registration.sign_up_terms_and_conditions'
        | transloco
          : {
              tac_url: 'https://examdojo.com/legal/terms-and-conditions',
              privacy_policy_url: 'https://examdojo.com/legal/privacy-policy'
            }
    "
  ></p>

  <p class="action-text flex justify-center gap-2 text-center">
    <span>{{ 'examdojo.registration.new_user_question' | transloco }}</span>
    <a class="dashed-link" [routerLink]="registerUrl">{{ 'examdojo.registration.sign_up' | transloco }}</a>
  </p>
</div>
