import {
  ChangeDetectionStrategy,
  Component,
  input,
  model,
  NgZone,
  OnDestroy,
  output,
  signal,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, FormGroupDirective, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatError } from '@angular/material/form-field';
import { ErrorMessages } from '@examdojo/core/form';
import { FormConfirmFn, SubmitButtonDirective } from '@examdojo/core/form-submit-button';
import { IconComponent } from '@examdojo/core/icon';
import { ButtonComponent } from '@examdojo/ui/button';
import { OteInputComponent } from '@examdojo/ui/ote-input/ote-input.component';
import { IonButton } from '@ionic/angular/standalone';
import { distinctUntilChanged, filter, tap } from 'rxjs';
import { ExamdojoAuthService } from '../abstract-auth.service';

@Component({
  selector: 'dojo-otp-verification-step',
  templateUrl: './otp-verification-step.component.html',
  styleUrl: './otp-verification-step.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatButtonModule,
    MatError,
    ReactiveFormsModule,
    IconComponent,
    SubmitButtonDirective,
    ButtonComponent,
    IonButton,
    OteInputComponent,
  ],
})
export class OtpVerificationStepComponent implements OnDestroy {
  constructor(
    private readonly authService: ExamdojoAuthService,
    private readonly ngZone: NgZone,
  ) {
    this.submitOtpFormWhenValid().pipe(takeUntilDestroyed()).subscribe();

    this.resetOtpResendTimer();
  }

  readonly error = model('');

  readonly otpResendTimer = signal(0);
  readonly OTP_RESEND_TIMER_INTERVAL = 30;
  private otpResendTimerIntervalRef: number | null = null;

  readonly canceled = output<void>();
  readonly completed = output<void>();
  readonly email = input.required<string>();

  readonly isVerifying = signal(false);

  readonly formRef = viewChild<FormGroupDirective>('form');

  readonly otpErrorMessages: ErrorMessages = {
    required: 'Code is required',
  };

  readonly otpForm = new FormGroup(
    {
      code: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
    },
    {
      updateOn: 'change',
    },
  );

  ngOnDestroy() {
    if (this.otpResendTimerIntervalRef) {
      clearInterval(this.otpResendTimerIntervalRef);
    }
  }

  async sendOTPCode() {
    this.resetOtpResendTimer();

    const { error } = await this.authService.signInWithOTP(this.email());

    this.error.set(error?.message ?? '');
  }

  goBack() {
    this.error.set('');

    this.otpForm.reset();

    this.canceled.emit();
  }

  maskEmail(email: string): string {
    const [localPart, domain] = email.split('@');
    if (!localPart || !domain) {
      return email;
    }

    return `${localPart[0]}*@${domain}`;
  }

  readonly otpConfirmFn: FormConfirmFn<typeof this.otpForm> = (form) => {
    return this.handleOTPVerification(form, this.email());
  };

  private submitOtpFormWhenValid() {
    return this.otpForm.statusChanges.pipe(
      filter((status) => status === 'VALID'),
      distinctUntilChanged(),
      tap(() => {
        this.formRef()?.ngSubmit.emit();
      }),
    );
  }

  private async handleOTPVerification(form: FormGroup, email: string) {
    if (form.invalid) {
      form.markAllAsTouched();
      return;
    }
    const { code } = form.getRawValue();

    this.isVerifying.set(true);
    const { error } = await this.authService.verifyOTP(email, code);

    if (error) {
      this.error.set(error.message);
      this.isVerifying.set(false);
    } else {
      // Keep the isVerifying flag to true to prevent re-submission
      this.error.set('');

      this.completed.emit();
    }
  }

  private resetOtpResendTimer() {
    this.otpResendTimer.set(this.OTP_RESEND_TIMER_INTERVAL);

    if (this.otpResendTimerIntervalRef) {
      clearInterval(this.otpResendTimerIntervalRef);
    }

    this.ngZone.runOutsideAngular(() => {
      this.otpResendTimerIntervalRef = window.setInterval(() => {
        this.ngZone.run(() => {
          this.otpResendTimer.update((v) => v - 1);
        });
      }, 1000);
    });
  }
}
