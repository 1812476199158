<div class="mx-auto flex h-full w-full max-w-[380px] flex-col justify-center px-4 py-4">
  <div class="text-typography-primary dark:font-medium-dark pb-6 text-center text-xl font-medium">
    Create a new password
  </div>

  <form [formGroup]="form" class="flex w-full flex-col">
    <y42-password-input
      label="New password"
      [formCtrl]="form.controls.password"
      data-test="reset-password-input"
      autocomplete="new-password"
      [errorMessages]="passwordErrorMessages"
    />

    <y42-password-input
      class="mt-4"
      label="Confirm password"
      [formCtrl]="form.controls.confirmPassword"
      data-test="reset-password-confirm-input"
      autocomplete="new-password"
      [errorMessages]="confirmPasswordErrorMessages"
    />

    <div class="mt-6">
      <dojo-classic-button
        color="primary"
        class="w-full"
        submitButton
        [confirmFn]="confirmFn"
        data-test="reset-password-button"
      >
        <span class="text-white">Reset password</span>
      </dojo-classic-button>
    </div>

    @if (error) {
      <mat-error class="mt-2 text-center">
        {{ error }}
      </mat-error>
    }
  </form>
</div>
