import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { ENV_NAME, EnvName, IS_PROD_BUILD } from '@examdojo/core/environment';
import { InternationalizationService } from '@examdojo/core/i18n';
import { assertNonNullable } from '@examdojo/core/util/assert';
import { getErrorMessage } from '@examdojo/core/util/get-error-message';
import { isAngularHttpError, isBackendError, isConnectionError } from '@examdojo/models/error-handler';
import { RichToastMessage, ToastService } from '@examdojo/toast';
import {
  catchError,
  Observable,
  ObservableInput,
  ObservedValueOf,
  OperatorFunction,
  Subject,
  switchMap,
  take,
  tap,
  throwError,
} from 'rxjs';
import { PosthogErrorHandlingService } from './posthog/posthog-error-handling.service';

type ErrorToast = string | RichToastMessage;
export type ErrorContext = Record<string, unknown>;
export type ErrorToastOptions<E = unknown> = ErrorToast | ((err: E | undefined) => ErrorToast | undefined);

export interface ErrorHandlerOptions<E = unknown> {
  toast?: ErrorToastOptions<E>;
  context?: object;
  tags?: Record<string, string>;
  err?: unknown;
}

interface ErrorMetadata {
  /**
   * The entity the error is related to.
   * Translations for this entity should be provided in the localization files.
   */
  entity: string;

  /**
   * The action being performed when the error occurred.
   * It does not have to correspond to the HTTP method, but translations must also be available for the action.
   */
  action?: 'fetch' | 'create' | 'update' | 'delete' | 'cancel';
}

@Injectable({ providedIn: 'root' })
export class ErrorHandlerService extends ErrorHandler {
  constructor(
    private readonly posthogErrorHandlingService: PosthogErrorHandlingService,
    private readonly injector: Injector,
    private readonly internalizationService: InternationalizationService,
  ) {
    super();
  }

  private readonly error$$ = new Subject<{
    message: string;
    err: unknown;
  }>();
  readonly error$ = this.error$$.asObservable();

  /**
   * This method is called by Angular - **do not use it**.
   *
   * Please use `ErrorHandlerService.error` instead.
   * @param error
   * @param options
   * @deprecated Please use `ErrorHandlerService.error` instead
   * @private
   */
  override handleError(error: unknown, options?: ErrorHandlerOptions): void {
    super.handleError(error);
    this.posthogErrorHandlingService.track(error);

    const envName = this.injector.get(ENV_NAME);
    this.handleErrorHandlerOptions(error, {
      ...(options || {}),
      toast: [EnvName.Local].includes(envName)
        ? (err) =>
            ({
              title: 'Unhandled Error',
              description: getErrorMessage(err),
            }) satisfies ErrorToastOptions
        : undefined,
    });
  }

  /**
   * Use this in place of the regular `rxjs/catchError`
   *
   * @param errorMessage the technical error message that will be displayed in PostHog
   * @param selector function that returns a new Observable value
   * @params options provide additional context or a toast message to be displayed
   */
  catchError<I, O extends ObservableInput<unknown>>(
    errorMessage: string,
    selector: (err: unknown, caught: Observable<I>) => O,
    options?: Omit<ErrorHandlerOptions, 'err'>,
  ): OperatorFunction<I, I | ObservedValueOf<O>> {
    return catchError((err: unknown, caught) => {
      this.error(errorMessage, {
        toast: options?.toast,
        context: options?.context,
        err,
        tags: { ...(options?.tags ?? {}), ...this.getTagsForError(err) },
      });
      return selector(err, caught);
    });
  }

  /**
   * Sets metadata on the error object that can be used later to display a more user-friendly error message.
   */
  setHttpErrorMetadata<I>(errorMetadata: ErrorMetadata): OperatorFunction<I, I> {
    return catchError((err: unknown) => {
      if (isBackendError(err)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (err as any).metadata = errorMetadata;
      }

      return throwError(() => err);
    });
  }

  /**
   * Use this in place of the regular `rxjs/catchError` to catch HTTP errors
   * that ideally has been marked before with ErrorHandlerService.setHttpErrorMetadata().
   */
  catchHttpErrors<I, O extends ObservableInput<unknown>>(
    selector: (err: unknown, caught: Observable<I>) => O,
  ): OperatorFunction<I, I | ObservedValueOf<O>> {
    return catchError((err: unknown, caught) => {
      if (!isBackendError(err)) {
        return throwError(() => err);
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const metadata = (err as any).metadata as ErrorMetadata | undefined;
      const entity = metadata?.entity ?? 'examdojo.entity.entity';
      const errorCode = isAngularHttpError(err) ? err.status : null;

      return this.internalizationService.selectTranslate(entity).pipe(
        take(1),
        tap((translatedEntity) => {
          let errorMessage: string;

          if (isConnectionError(err)) {
            errorMessage = this.internalizationService.translate('examdojo.http_errors.offline');
          } else {
            errorMessage = this.getDefaultHttpErrorMessage(translatedEntity, errorCode, metadata?.action);
          }

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (err as any).customMessage = errorMessage;

          this.error(`[${entity}]`, {
            toast: errorMessage,
            err,
            context: {
              errorCode,
              action: metadata?.action,
            },
          });
        }),
        switchMap(() => selector(err, caught)),
      );
    });
  }

  /**
   * Reports an event to PostHog and marks it as an error
   * @param errorMessage
   * @param options
   */
  error(errorMessage: string, options?: ErrorHandlerOptions): void {
    if (IS_PROD_BUILD) {
      console.error(errorMessage, options?.context || '');
    } else {
      // This will produce a stacktrace in the console, which makes debugging a lot easier
      console.error(new Error(errorMessage));

      if (options?.err) {
        console.error('[Original error]', options.err);
      }
      if (options?.context) {
        console.error('[Error Context]', options.context);
      }
    }

    this.captureMessage({
      errorMessage,
      level: 'error',
      options,
    });
    this.error$$.next({
      message: errorMessage,
      err: options?.err,
    });
  }

  /**
   * Reports an event to PostHog and marks it as a log
   * @param errorMessage
   * @param options
   */
  log(errorMessage: string, options?: ErrorHandlerOptions): void {
    if (!IS_PROD_BUILD) {
      console.warn(errorMessage, options?.context || '');
    }
    this.captureMessage({
      errorMessage,
      level: 'log',
      options,
    });
  }

  assertNonNullable(value: unknown, name: string, options?: ErrorHandlerOptions): asserts value {
    try {
      assertNonNullable(value, name);
    } catch (err) {
      this.error((err as Error).message, options);
      throw err;
    }
  }

  private captureMessage({
    errorMessage,
    options,
  }: {
    errorMessage: string;
    level: 'error' | 'warning' | 'log';
    options?: ErrorHandlerOptions;
  }): void {
    const { context, err } = options || {};
    const contextWithError = {
      ...context,
      ...(err ? { originalError: err } : {}),
    };

    this.posthogErrorHandlingService.trackEvent(errorMessage, contextWithError);

    this.handleErrorHandlerOptions(options?.err, options);
  }

  private handleErrorHandlerOptions(err: unknown | undefined, options?: ErrorHandlerOptions): void {
    const toast = options?.toast;
    const message = toast ? (typeof toast === 'function' ? toast(err) : toast) : undefined;

    if (message) {
      this.injector.get(ToastService).error(message);
    }
  }

  private getTagsForError(err: unknown): Record<string, string> {
    const tags: Record<string, string> = {};

    if (err instanceof HttpErrorResponse) {
      tags['http_status'] = `${err.status}`;
    }

    return tags;
  }

  private getDefaultHttpErrorMessage(
    entity: string,
    errorCode?: number | null,
    action: ErrorMetadata['action'] = 'fetch',
    // TODO: handle plural forms.
  ): string {
    const params = {
      entity,
      action: this.internalizationService.translate(`examdojo.errors_actions.${action}`),
    };

    if (!errorCode) {
      return this.internalizationService.translate('examdojo.http_errors.generic', params);
    }

    if (errorCode === 401) {
      return this.internalizationService.translate('examdojo.http_errors.unauthorized', params);
    }

    if (errorCode === 403) {
      return this.internalizationService.translate('examdojo.http_errors.forbidden', params);
    }

    if (errorCode === 404) {
      if (action !== 'create') {
        // For a 404 create action it does not make sense to show a not found error
        // But perhaps the BE should return a different error code for this case
        return this.internalizationService.translate('examdojo.http_errors.not_found', params);
      } else {
        return this.internalizationService.translate('examdojo.http_errors.generic', params);
      }
    }

    if (errorCode >= 500 && errorCode < 600) {
      return this.internalizationService.translate('examdojo.http_errors.server_error', params);
    }

    return this.internalizationService.translate('examdojo.http_errors.generic', params);
  }
}
