<form
  [formGroup]="otpForm"
  #form="ngForm"
  class="small:pb-0 relative flex h-full w-full flex-col justify-center pb-[50px]"
>
  <div class="mb-8">
    <ion-button class="go-back-button inline-flex items-center" (click)="goBack()">
      <dojo-icon [icon]="'arrow-left'" />
      <span class="ml-1">Go back</span>
    </ion-button>
  </div>
  <h2 class="text-display-md font-medium-exam mb-2 text-neutral-950">Verify email</h2>
  <div class="small:mb-8 font-normal-exam mb-6 text-sm text-stone-600">
    Code has been sent to {{ maskEmail(email()) }}
  </div>

  <div class="small:mb-6 relative mb-4">
    <dojo-ote-input [formCtrl]="otpForm.controls.code" data-test="sign-in-otp-code" />

    @if (otpForm.controls.code.hasError('required') && otpForm.controls.code.touched) {
      <mat-error class="text-body-s absolute ml-1 mt-2 before:!hidden">
        {{ otpErrorMessages['required'] }}
      </mat-error>
    }

    @if (error()) {
      <mat-error class="text-body-s absolute ml-1 mt-2 before:!hidden">
        {{ error() }}
      </mat-error>
    }
  </div>

  <div class="font-normal-exam small:flex-no-wrap flex flex-wrap gap-2 text-sm">
    <span class="text-stone-600">Didn't receive the OTP code?</span>
    @if (otpResendTimer() > 0) {
      <span class="font-medium-exam text-stone-600"
        >Resend code in <span class="inline-flex w-[32px]">{{ otpResendTimer() }}s</span></span
      >
    } @else {
      <a class="font-medium-exam flex items-center gap-1 text-blue-600 hover:text-blue-800" (click)="sendOTPCode()">
        <span>Resend code</span>
        <dojo-icon [icon]="'arrow-rotate-left'"></dojo-icon>
      </a>
    }
  </div>
  <div class="small:static small:inline-block absolute bottom-0 left-0 right-0 mt-8">
    <dojo-button
      class="small:inline-flex"
      submitButton
      [confirmFn]="otpConfirmFn"
      [disabled]="isVerifying()"
      data-test="login-button"
    >
      Verify
    </dojo-button>
  </div>
</form>
